import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import CarouselPage from './pages/Carousel';
import BookingPage from './pages/Prenota';
import Privacy from './pages/Privacy';
import TermsAndCondition from './pages/Terms';
import './App.css';

const App = () => {
  useEffect(() => {
    const updateContentHeight = () => {
      const headerHeight = document.querySelector('header').offsetHeight;
      document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
      document.documentElement.style.setProperty('--content-height', `calc(100vh - ${headerHeight}px)`);
    };

    updateContentHeight();
    window.addEventListener('resize', updateContentHeight);

    return () => window.removeEventListener('resize', updateContentHeight);
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header iconPath="/icons/favicon.svg" />

          <PageTransitions />

        <Footer />
      </div>
    </Router>
  );
};

const PageTransitions = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={1000}>
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/prenota" element={<BookingPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<TermsAndCondition />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;