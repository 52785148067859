import React, { useState, useEffect } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const menuItems = {
  'Home': '/',
  'Chi sono': '/about',
  'Contattami' : '/contact'
};

const CustomButton = ({ children, isScrolled, className = '', to, onClick }) => (
  <div className={`inline-block button ${className}`} onClick={onClick}>
    <Link
      to={to}
      className={`px-8 py-5 rounded-15 transition-colors duration-300 ${
        isScrolled
          ? 'bg-green text-text-light hover:bg-accent hover:text-white'
          : 'bg-beige text-text-dark hover:bg-accent hover:text-white'
      }`}
    >
      {children}
    </Link>
  </div>
);

const Header = ({ iconPath }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 30);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <header className={`sticky top-0 z-50 transition-all duration-500  ${isScrolled ? 'bg-beige-light ' : 'bg-beige-light'} h-[100px] p-2 flex items-center` }>
      <div className="container mx-0 px-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="bg-transparent w-15 h-15 rounded-full mr-2 flex items-center justify-center transition-transform duration-300 ease-in-out hover:scale-110">
              <img 
                src={iconPath}
                alt="Alessandra Filzoli Icon"
                className="w-15 h-15"
              />
            </div>
            <Link to="/" className={`text-3xl flex items-center font-black leading-tight transition-colors duration-300 ${isScrolled ? 'text-text-dark hover:text-accent' : 'text-text-dark hover:text-accent'}`}>
              Alessandra Filzoli
            </Link>
          </div>
          <nav className={`hidden md:flex md:space-x-6 items-center text-lg`}>
            {Object.entries(menuItems).map(([name, path]) => (
              <Link
                key={name}
                to={path}
                className={`transition-colors body_tile duration-300 hover:text-accent ${isScrolled ? 'text-green-dark' : 'text-green-dark'}`}
              >
                {name}
              </Link>
            ))}
            <CustomButton isScrolled={isScrolled} to="/prenota">
              Prenota una visita
            </CustomButton>
          </nav>
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu} className="text-green-dark hover:text-accent z-50">
              {isMenuOpen ? <X size={35} /> : <Menu size={35} />}
            </button>
          </div>
        </div>
      </div>
      {/* Full-screen mobile menu with transition */}
      <div className={`fixed inset-0 bg-green-dark z-40 flex flex-col justify-between transition-opacity duration-300 ease-in-out ${isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} md:hidden`}>
        <div className="flex justify-between items-center pl-6 pt-8">
          <div className="flex items-center">
            <div className="bg-transparent w-15 h-15 rounded-full mr-2 flex items-center justify-center">
              <img 
                src={iconPath}
                alt="Alessandra Filzoli Icon"
                className="w-15 h-15"
              />
            </div>
            <div className="text-3xl font-bold text-text-light">
              Alessandra Filzoli
            </div>
          </div>
        </div>
        <nav className="flex-grow flex flex-col justify-center items-center space-y-8 text-3xl font-semibold">
          {Object.entries(menuItems).map(([name, path]) => (
            <Link
              key={name}
              to={path}
              className="transition-colors text-text-light duration-300 hover:text-accent text-green-dark"
              onClick={closeMenu}
            >
              {name}
            </Link>
          ))}
          <CustomButton isScrolled={isScrolled} to="/prenota" onClick={closeMenu} className="text-2xl">
            Prenota una visita
          </CustomButton>
        </nav>
        <div className="h-20"></div> {/* Spacer to push content up slightly */}
      </div>
    </header>
  );
};


export default Header;