import React, { useRef, useEffect } from 'react';

const ScrollToSectionButton = ({ targetId, className, children }) => {
  const targetRef = useRef(null);

  useEffect(() => {
    targetRef.current = document.getElementById(targetId);
  }, [targetId]);

  const handleClick = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <button className={className} onClick={handleClick}>
      {children}
    </button>
  );
};

export default ScrollToSectionButton;