import React, { useState, useRef } from 'react';
import { Send, ChevronDown } from 'lucide-react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    privacyAccepted: false,
    newsletterSubscribe: false
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [customMessage, setCustomMessage] = useState('');

  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const submitContactForm = async (formData) => {
    try {
      const response = await fetch('/api/submit-contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        return {
          status: 'error',
          message: 'Si è verificato un errore durante l\'elaborazione della tua richiesta. Per favore, riprova più tardi.'
        };
      }
    } catch (error) {
      console.error('Submission error:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await submitContactForm(formData);
      setSubmissionStatus(result.status);
      setCustomMessage(result.message);
    } catch (error) {
      setSubmissionStatus('error');
      setCustomMessage('Si è verificato un errore durante l\'invio del modulo. Per favore, riprova più tardi.');
    }
  };

  const renderForm = () => (
    <div className="bg-white rounded-30 p-6 shadow-sm hover:shadow-lg transition-shadow duration-300 ">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-text-dark mb-2">
            Nome e Cognome *
          </label>
          <input
            type="text"
            id="name"
            name="name"
            required
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-3 rounded-full border border-beige-dark/20 focus:outline-none focus:ring-2 focus:ring-accent"
          />
        </div>
        
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-text-dark mb-2">
            Email *
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-3 rounded-full border border-beige-dark/20 focus:outline-none focus:ring-2 focus:ring-accent"
          />
        </div>
        
        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-text-dark mb-2">
            Numero di Telefono
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-4 py-3 rounded-full border border-beige-dark/20 focus:outline-none focus:ring-2 focus:ring-accent"
          />
        </div>
        
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-text-dark mb-2">
            Messaggio *
          </label>
          <textarea
            id="message"
            name="message"
            required
            value={formData.message}
            onChange={handleChange}
            rows="4"
            className="w-full px-4 py-3 rounded-3xl border border-beige-dark/20 focus:outline-none focus:ring-2 focus:ring-accent min-h-[120px]"
          />
        </div>
        
        <div className="space-y-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="privacyAccepted"
              required
              checked={formData.privacyAccepted}
              onChange={handleChange}
              className="mr-2"
            />
            <span className="text-sm text-text-dark">
              Accetto la <a href="/privacy" className="text-accent hover:underline">politica sulla privacy</a> *
            </span>
          </label>
          
          <label className="flex items-center">
            <input
              type="checkbox"
              name="newsletterSubscribe"
              checked={formData.newsletterSubscribe}
              onChange={handleChange}
              className="mr-2"
            />
            <span className="text-sm text-text-dark">
              Desidero iscrivermi alla newsletter
            </span>
          </label>
        </div>
        
        <button
          type="submit"
          className="w-full flex items-center justify-center px-6 py-4 bg-beige-dark text-white rounded-full hover:bg-accent transition-colors duration-300"
        >
          <Send className="mr-2" size={20} />
          Invia Messaggio
        </button>
      </form>
    </div>
  );

  const renderResult = () => {
    const results = {
      success: {
        className: "text-green",
        title: "Messaggio Inviato!",
        icon: "✓",
        message: "Grazie per avermi contattato! Ti risponderò il prima possibile."
      },
      error: {
        className: "text-red-600",
        title: "Errore!",
        icon: "✕",
        message: "Si è verificato un errore durante l'invio del messaggio. Riprova più tardi."
      },
      warning: {
        className: "text-yellow-600",
        title: "Attenzione!",
        icon: "⚠",
        message: "Verifica i dati inseriti e riprova."
      },
      default: {
        className: "text-yellow-600",
        title: "Stato Sconosciuto",
        icon: "?",
        message: "Si è verificato uno stato imprevisto. Riprova più tardi."
      }
    };
  
    const result = results[submissionStatus] || results.default;
    
    return (
      <div className="bg-white rounded-30 p-6 shadow-sm hover:shadow-lg transition-shadow duration-300">
        <div className="flex flex-col items-center justify-center min-h-[400px] space-y-6 text-center">
          <span className={`text-5xl ${result.className}`}>
            {result.icon}
          </span>
          <h2 className={`text-2xl font-medium ${result.className}`}>
            {result.title}
          </h2>
          <p className="text-text-dark max-w-md">
            {result.message || customMessage}
          </p>
          <button
            onClick={() => window.location.reload()}
            className="flex items-center justify-center px-6 py-4 bg-beige-dark text-white rounded-full hover:bg-accent transition-colors duration-300"
          >
            Invia nuovo messaggio
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Hero Section (Mobile Only) */}
      <section className="md:hidden relative min-h-[var(--content-height)] flex flex-col justify-end pb-16">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{ 
            backgroundImage: 'url("/images/contact/nature_harmony.webp")',
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-beige-light/60 to-beige-light/100"></div>
        </div>
        
        <div className="relative z-10 px-4 text-center">
          <h1 className="h1 text-green mb-4">Contattami</h1>
          <p className="text-text-dark max-w-xl mx-auto mb-8">
            Per qualsiasi tipo di dubbio o informazione contattami tramite il form a lato, e ti risponderó il prima possibile.
          </p>
          <button 
            onClick={scrollToForm}
            className="inline-flex items-center justify-center gap-2 text-green animate-bounce pb-4"
            aria-label="Scorri al form di contatto"
          >
            Scorri giù
            <ChevronDown size={24} />
          </button>
        </div>
      </section>

      {/* Main Content Section */}
      <section className="relative min-h-[600px] flex items-center bg-beige-light md:min-h-[var(--content-height)] py-8 md:py-4">
        <div className="container relative z-10 mx-auto px-4">
          {/* Title and description container - Only visible on desktop */}
          <div className="hidden md:block text-center mb-12">
            <h1 className="h1 text-green mb-4">Contattami</h1>
            <p className="text-text-dark max-w-xl mx-auto">
              Per qualsiasi tipo di dubbio o informazione contattami tramite il form a lato, e ti risponderó il prima possibile.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
            {/* Left column - Image (desktop only) */}
            <div className="hidden md:block h-full">
              <div className="sticky top-0 h-[calc(80vh-2rem)]"> {/* Subtract padding */}
                <div className="h-full w-full overflow-hidden rounded-3xl">
                  <img 
                    src="/images/contact/nature_harmony.webp" 
                    alt="Nature Harmony" 
                    className="h-full w-full object-cover object-center transition-all duration-300"
                  />
                </div>
              </div>
            </div>

            {/* Right column - Form */}
            <div ref={formRef} className="w-full h-full scroll-mt-4">
              {submissionStatus !== null ? renderResult() : renderForm()}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;