import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import { getCalApi } from "@calcom/embed-react";

const AppointmentBookingPage = () => {
  const [isWaitingListFormVisible, setIsWaitingListFormVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [customMessage, setCustomMessage] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({"namespace":"visita-omnicomprensiva"});
      cal("ui", {
        "theme": "light",
        "styles": {
          "branding": {
            "brandColor": "#89ad4c"
          }
        },
        "hideEventTypeDetails": false,
        "layout": "month_view"
      });
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setSubmissionStatus('error');
      setCustomMessage('Per favore, inserisci un indirizzo email valido.');
      return;
    }

    if (!acceptTerms) {
      setSubmissionStatus('error');
      setCustomMessage('Per favore, accetta la privacy policy e i termini e condizioni per procedere.');
      return;
    }

    try {
      const result = await subscribeToWaitingList(email);
      setSubmissionStatus(result.status);
      setCustomMessage(result.message);
    } catch (error) {
      console.error('Error:', error);
      setSubmissionStatus('error');
      setCustomMessage('Si è verificato un errore imprevisto. Per favore, riprova più tardi.');
    }
  };

  const subscribeToWaitingList = async (email) => {
    try {
      const response = await fetch('/api/subscribe-waitinglist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
      });
  
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        return {
          status: 'error',
          message: 'Si è verificato un errore durante l\'elaborazione della tua richiesta. Per favore, riprova più tardi.'
        };
      }
    } catch (error) {
      console.error('Waiting list subscription error:', error);
      throw error;
    }
  };

  const renderWaitingListForm = () => (
    <form onSubmit={handleSubmit} className="bg-white p-8 rounded-30 shadow-md">
      <div className="mb-4">
        <label htmlFor="email" className="block body_tile font-medium text-gray-700 mb-4">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-5 py-3 body text-text-dark border border-gray-300 rounded-15 focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>
      <div className="mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={acceptTerms}
            onChange={(e) => setAcceptTerms(e.target.checked)}
            className="form-checkbox h-5 w-5 text-green-500"
          />
          <span className="ml-2 text-sm text-gray-700">
            Accetto la <a href="/privacy" className="text-blue-600 hover:underline">privacy policy</a> e i <a href="/terms" className="text-blue-600 hover:underline">termini e condizioni</a>
          </span>
        </label>
      </div>
      <button
        type="submit"
        className="w-full button bg-beige-dark text-beige-light mb-6 px-10 py-5 rounded-1 hover:bg-accent transition-colors"
        disabled={!acceptTerms}
      >
        Iscriviti
      </button>
      <p className="text-right text-text-dark">
        La tua email verrá tenuta nel sistema per 30 giorni prima di essere eliminata
      </p>
    </form>
  );

  const renderResult = () => {
    const results = {
      success: {
        className: "bg-green-100 border-l-4 border-green-500 text-green-700",
        title: "Iscrizione confermata!",
      },
      info: {
        className: "bg-blue-100 border-l-4 border-blue-500 text-blue-700",
        title: "Informazione",
      },
      error: {
        className: "bg-red-100 border-l-4 border-red-500 text-red-700",
        title: "Errore!",
      }
    };

    const result = results[submissionStatus];
    return (
      <div className={`${result.className} p-4 rounded`} role="alert">
        <p className="font-bold">{result.title}</p>
        <p>{customMessage}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col bg-beige">
      {/* Booking Section */}
      <section className="relative flex items-center bg-beige-light" style={{ minHeight: 'var(--content-height)' }}>
        {/* Mobile background */}
        <div 
          className="absolute inset-0 bg-cover bg-top bg-no-repeat md:hidden"
          style={{ 
            backgroundImage: 'url("/images/prenota/tree_on_the_hill.webp" )',
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-beige-light/75 to-beige-light"></div>
        </div>
        
        <div className="container relative z-10 mx-auto px-4 flex flex-col md:flex-row md:items-center h-full">
          {/* Mobile layout - positioned at bottom */}
          <div className="md:hidden flex flex-col justify-end w-full pb-8" style={{ minHeight: 'var(--content-height)' }}>
            <div className="p-4 rounded-15 mt-auto"> {/* Added mt-auto here */}
              <h1 className="h1 text-green mb-4">
                Prenota la tua visita
              </h1>
              <p className="body text-text-dark mt-4 mb-6">
                Seleziona il giorno a te congeniale per la tua visita! Ogni giorno nuove date di appuntamento vengono rilasciate. Se non trovi date disponibili, prova a controllare domani, oppure iscriviti alla waiting list.
              </p>
              <button
                data-cal-namespace="visita-omnicomprensiva"
                data-cal-link="alessandra-filzoli/visita-omnicomprensiva"
                data-cal-config='{"layout":"month_view","theme":"light"}'
                className="flex h4 items-center justify-center px-6 py-5 w-full bg-beige-dark text-white font-bold rounded-full hover:bg-accent transition-colors duration-300 shadow-md"
              >
                Prenota ora
              </button>
            </div>
          </div>
  
          {/* Desktop/Tablet layout - centered vertically */}
          <div className="hidden md:flex md:w-1/2 md:pr-8 flex-col justify-center">
            <h1 className="h1 text-green">
              Prenota la tua visita
            </h1>
            <p className="body text-text-dark mt-4 mb-6">
              Seleziona il giorno a te congeniale per la tua visita! Ogni giorno nuove date di appuntamento vengono rilasciate. Se non trovi date disponibili, prova a controllare domani, oppure iscriviti alla waiting list.
            </p>
            <button
              data-cal-namespace="visita-omnicomprensiva"
              data-cal-link="alessandra-filzoli/visita-omnicomprensiva"
              data-cal-config='{"layout":"month_view","theme":"light"}'
              className="flex h4 items-center justify-center px-6 py-6 w-full md:w-auto bg-beige-dark text-beige-light font-bold rounded-15 hover:bg-accent transition-colors duration-300 shadow-md"
            >
              Prenota ora
            </button>
          </div>
  
          {/* Desktop/Tablet image */}
          <div className="hidden md:block md:w-1/2">
            <div className="w-full overflow-hidden">
              <img 
                src="/images/prenota/tree_on_the_hill.webp" 
                alt="Immagine di un pacifico albero su una collina"
                className="w-full h-[80vh] rounded-30 shadow-lg object-cover object-center"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Waiting List Section */}
      <div className="bg-green text-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h2 className="h2 text-text-light mb-4 text-center">Iscriviti alla waiting list</h2>
          <p className="body text-beige text-center mb-4">Verrai contattato per email se qualche posto si libererà!</p>
          <div className="text-center">
            <button
              onClick={() => setIsWaitingListFormVisible(!isWaitingListFormVisible)}
              className="button bg-beige-dark text-beige-light px-8 py-5 rounded-15 hover:bg-accent transition-colors"
            >
              {isWaitingListFormVisible ? 'Chiudi' : 'Iscriviti alla waiting list'}
            </button>
          </div>

          <AnimatePresence>
            {isWaitingListFormVisible && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="overflow-hidden mt-8"
              >
                {submissionStatus === null ? renderWaitingListForm() : renderResult()}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AppointmentBookingPage;