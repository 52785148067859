import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-background py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-[1200px] w-full mx-auto">
        <h1 className="h1 text-text-dark mb-6">Informativa sulla Privacy per alessandrafilzoli.com</h1>
        
        <p className="body text-text-dark mb-6">Ultimo aggiornamento: 14/10/2024</p>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">1. Introduzione</h2>
          <p className="body text-text-dark mb-4">
            La presente Informativa sulla Privacy spiega come Alessandra Filzoli ("noi", "ci" o "nostro") raccoglie, utilizza e protegge i dati personali degli utenti del sito web alessandrafilzoli.com ("Sito"). Rispettiamo la tua privacy e ci impegniamo a proteggere i tuoi dati personali.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">2. Dati raccolti</h2>
          <p className="body text-text-dark mb-4">Raccogliamo i seguenti dati personali attraverso tre diversi form sul nostro Sito:</p>
          <ul className="list-disc list-inside body text-text-dark mb-4">
            <li>Form di iscrizione alla newsletter: indirizzo email</li>
            <li>Form di iscrizione alla waiting list: indirizzo email</li>
            <li>Form di prenotazione appuntamenti: indirizzo email, nome, numero di telefono</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">3. Modalità di raccolta</h2>
          <p className="body text-text-dark mb-4">
            I dati vengono raccolti direttamente dagli utenti attraverso la compilazione volontaria dei form presenti sul Sito.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">4. Finalità del trattamento</h2>
          <p className="body text-text-dark mb-4">Utilizziamo i dati raccolti per le seguenti finalità:</p>
          <ul className="list-disc list-inside body text-text-dark mb-4">
            <li>Invio di newsletter (se l'utente si è iscritto)</li>
            <li>Gestione della waiting list</li>
            <li>Prenotazione e gestione degli appuntamenti</li>
            <li>Analisi statistiche interne per migliorare i nostri servizi</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">5. Base giuridica</h2>
          <p className="body text-text-dark mb-4">
            Il trattamento dei dati personali si basa sul consenso dell'utente, espresso attraverso la compilazione volontaria dei form.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">6. Conservazione dei dati</h2>
          <p className="body text-text-dark mb-4">
            Conserviamo i dati personali per il tempo necessario al raggiungimento delle finalità per cui sono stati raccolti, o fino alla richiesta di cancellazione da parte dell'utente.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">7. Condivisione dei dati e uso di servizi di terze parti</h2>
          <h3 className="h3 text-text-dark mb-4">7.1 Generale</h3>
          <p className="body text-text-dark mb-4">
            Non condividiamo i dati personali raccolti con terze parti, ad eccezione di quanto specificato nella sezione 7.2.
          </p>
          <h3 className="h3 text-text-dark mb-4">7.2 Servizio newsletter - Brevo</h3>
          <p className="body text-text-dark mb-4">
            Per la gestione del servizio newsletter, utilizziamo Brevo, un fornitore di servizi di email marketing. Accettando la nostra privacy policy per l'invio di newsletter, l'utente accetta anche la privacy policy di Brevo. I contatti forniti per la newsletter vengono salvati all'interno dei servizi di Brevo.
          </p>
          <p className="body text-text-dark mb-4">
            Per ulteriori informazioni su come Brevo tratta i dati personali, invitiamo gli utenti a consultare la <a href="https://www.brevo.com/legal/privacypolicy/" className="text-blue-600 hover:underline">privacy policy di Brevo</a>.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">8. Diritti degli utenti</h2>
          <p className="body text-text-dark mb-4">Gli utenti hanno il diritto di:</p>
          <ul className="list-disc list-inside body text-text-dark mb-4">
            <li>Accedere ai propri dati personali</li>
            <li>Richiedere la rettifica o la cancellazione dei dati</li>
            <li>Limitare o opporsi al trattamento</li>
            <li>Richiedere la portabilità dei dati</li>
            <li>Revocare il consenso in qualsiasi momento</li>
          </ul>
          <p className="body text-text-dark mb-4">
            Per esercitare questi diritti, contattare [inserire indirizzo email di contatto].
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">9. Sicurezza</h2>
          <p className="body text-text-dark mb-4">
            Adottiamo misure di sicurezza tecniche e organizzative appropriate per proteggere i dati personali da accessi non autorizzati, perdite o alterazioni. Anche Brevo implementa misure di sicurezza per proteggere i dati degli iscritti alla newsletter.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">10. Cookie</h2>
          <p className="body text-text-dark mb-4">
            Il nostro Sito utilizza cookie tecnici necessari al funzionamento del sito. Non utilizziamo cookie di profilazione o di terze parti.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">11. Modifiche alla privacy policy</h2>
          <p className="body text-text-dark mb-4">
            Ci riserviamo il diritto di modificare questa privacy policy in qualsiasi momento. Le modifiche saranno pubblicate su questa pagina con la data di ultimo aggiornamento.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">12. Contatti</h2>
          <p className="body text-text-dark mb-4">
            Per qualsiasi domanda riguardante questa privacy policy o il trattamento dei dati personali, contattare:
          </p>
          <p className="body text-text-dark mb-4">
            Alessandra Filzoli<br />
            Email: support@alessandrafilzoli.com<br />
            Indirizzo: Piazza San Vito 24, Lentate Sul Seveso, MB, Italia
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;