import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-background py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-[1200px] w-full mx-auto">
        <h1 className="h1 text-text-dark mb-6">Termini e Condizioni di Utilizzo</h1>
        
        <p className="body text-text-dark mb-6">Ultimo aggiornamento: 14/10/2024</p>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">1. Accettazione dei Termini</h2>
          <p className="body text-text-dark mb-4">
            Utilizzando il sito web alessandrafilzoli.com e i servizi ad esso collegati, l'utente accetta di essere vincolato dai seguenti termini e condizioni.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">2. Iscrizione ai Servizi</h2>
          <p className="body text-text-dark mb-4">
            Iscrivendosi ai nostri servizi, l'utente conferma che l'indirizzo email fornito è di sua proprietà e che tutte le informazioni fornite sono veritiere e accurate.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">3. Prenotazione e Cancellazione degli Appuntamenti</h2>
          <p className="body text-text-dark mb-4">
            Prenotando un appuntamento, l'utente si impegna a:
          </p>
          <ul className="list-disc list-inside body text-text-dark mb-4">
            <li>Fornire informazioni accurate e veritiere.</li>
            <li>Avvisare con almeno 24 ore di anticipo in caso di necessità di cancellazione o riprogrammazione dell'appuntamento.</li>
          </ul>
          <p className="body text-text-dark mb-4">
            In caso di mancato rispetto di queste condizioni, ci riserviamo il diritto di:
          </p>
          <ul className="list-disc list-inside body text-text-dark mb-4">
            <li>Contrassegnare l'indirizzo email dell'utente.</li>
            <li>Rifiutare future prenotazioni da parte dell'utente.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">4. Utilizzo Responsabile</h2>
          <p className="body text-text-dark mb-4">
            L'utente si impegna a utilizzare il sito e i servizi in modo responsabile e nel rispetto degli altri utenti e dello staff.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">5. Modifiche ai Termini</h2>
          <p className="body text-text-dark mb-4">
            Ci riserviamo il diritto di modificare questi termini in qualsiasi momento. Le modifiche entreranno in vigore immediatamente dopo la pubblicazione sul sito.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="h2 text-text-dark mb-4">6. Contatti</h2>
          <p className="body text-text-dark mb-4">
            Per qualsiasi domanda riguardante questi termini e condizioni, contattare:
          </p>
          <p className="body text-text-dark mb-4">
          Alessandra Filzoli<br />
            Email: support@alessandrafilzoli.com<br />
            Indirizzo: Piazza San Vito 24, Lentate Sul Seveso, MB, Italia
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;